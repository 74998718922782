import React from 'react';
import NavBar from './NavBar';
import Section from './Section';
import Services from './Services';
import About from './About';
import WorkProcess from './WorkProcess';
import WhatWeTreat from './WhatWeTreat';
import Workshops from './Workshops';
import Footer5 from '../Pages/PageFooterLayouts/Footer5';
import Popup from '../../components/Layout/popup';
import '../../assets/css/custom-colors.css';

const Index = () => {
    return (
        <React.Fragment>
            <NavBar />
            <Section />
            <Footer5 />
            <Popup />
        </React.Fragment>
    );
};

export default Index; 