import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import NavBar from './NavBar';
import Footer5 from '../Pages/PageFooterLayouts/Footer5';

const workshopData = [
  {
    icon: "uil uil-presentation-lines-alt",
    title: "Professional Development",
    description: "Training for mental health professionals on trauma-informed care and somatic interventions.",
    audience: "Therapists, Counselors, Social Workers"
  },
  {
    icon: "uil uil-building",
    title: "Workplace Wellness",
    description: "Corporate workshops on stress management, team building, and emotional intelligence.",
    audience: "Organizations, Companies, Teams"
  },
  {
    icon: "uil uil-graduation-cap",
    title: "Educational Institutions",
    description: "Programs for schools on mental health awareness, trauma-informed education, and staff training.",
    audience: "Schools, Universities, Teachers"
  }
];

const Workshops = () => {
  const location = useLocation();
  const isStandalonePage = location.pathname === '/workshops';

  return (
    <React.Fragment>
      <NavBar />
      <section className="section">
        <Container>
          <Row className="align-items-center">
            <Col md={8}>
              <div className="section-title text-center text-md-start">
                <h6 className="text-primary">Workshops</h6>
                <h4 className="title mb-4">Professional Training & Workshops</h4>
                <p className="text-muted mb-0 para-desc">
                  Specialized training and workshops designed to enhance professional development 
                  and promote mental health awareness in various settings.
                </p>
              </div>
            </Col>

            <Col md={4} className="mt-4 mt-sm-0">
              <div className="text-center text-md-end">
                <Link to="/contact" className="text-primary h6">
                  Book Workshop <i className="uil uil-angle-right-b align-middle"></i>
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            {workshopData.map((workshop, index) => (
              <Col lg={4} md={6} className="mt-4 pt-2" key={index}>
                <Card className="features feature-clean course-feature p-4 overflow-hidden shadow rounded">
                  <div className="icons text-primary text-center">
                    <i className={`${workshop.icon} d-block rounded h3 mb-0`}></i>
                  </div>
                  <CardBody className="p-0 mt-4">
                    <h5 className="title text-dark">{workshop.title}</h5>
                    <p className="text-muted mt-2">{workshop.description}</p>
                    <div className="mt-3">
                      <span className="text-primary">For: {workshop.audience}</span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row className="justify-content-center mt-5">
            <Col lg={7} md={10} className="text-center">
              <div className="section-title">
                <h4 className="title mb-4">Let's discuss your training needs</h4>
                <p className="text-muted para-desc mx-auto">
                  Custom workshops and training programs available for your organization's specific needs.
                </p>
                <div className="mt-4 pt-2">
                  <Link to="/contact" className="btn btn-primary">
                    Get Started Now
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          
          <div className="mt-5"></div>
        </Container>
      </section>
      {isStandalonePage && <Footer5 />}
    </React.Fragment>
  );
};

export default Workshops; 