import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../../assets/css/section.css';

const Section = () => {
  return (
    <React.Fragment>
      <section className="hero-section" 
        style={{ 
          backgroundImage: `url(${require('../../assets/images/therapy/homepage.jpg')})`
        }}
      >
        <Container className="hero-container">
          <Row className="justify-content-center">
            <Col xs={12} sm={10} md={8} className="text-center">
              <div className="content-wrapper">
                <h1 className="hero-title mb-3">
                  <span style={{fontSize: "1.2em"}}>Esther Kahan-Nadler</span> <br />
                  <span style={{fontSize: "0.9em"}}>LCSW, CASAC</span>
                </h1>
                <p className="hero-subtitle text-muted">
                  Individual Therapy | Group Therapy | Therapy with Movement and Dance | Business Consulting
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="quote-wrapper">
          <p className="quote-text">
            "The curious paradox is that when I accept myself just as I am, then I can change."
          </p>
          <p className="quote-author">- Carl Rogers</p>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Section; 