import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import NavBar from './NavBar';
import Footer5 from '../Pages/PageFooterLayouts/Footer5';

// Import images
import founderImage from "../../assets/images/therapy/esther.png";
import assistantImage from "../../assets/images/therapy/assistant-pic.jpg";

const Team = () => {
  const teamMembers = [
    {
      id: 1,
      image: founderImage,
      name: "Esther Kahan-Nadler",
      designation: "LCSW, CASAC",
      title: "Founder",
      description: `Esther Kahan is a licensed clinical social worker in the state of New York who has devoted her career to helping individuals through all of life's moments, from challenges to personal growth. Inspired by witnessing the resilience of people facing adversity, Esther has made it her mission to guide clients toward healing and personal growth.

      With experience in diverse settings, including hospital and inpatient/outpatient programs, and intensive outpatient programs, Esther has developed a deep expertise in trauma, substance use, grief, and life transitions. She is also a graduate of the dance therapy program through the renowned 92Y program, and she integrates movement-based and somatic approaches to foster healing and self-expression. Her approach is rooted in the belief that everyone has the capacity for transformation and that healing begins in a space where clients feel truly seen, heard, and valued.`,
      socialIds: [
        { icon: "instagram", link: "https://www.instagram.com/estynadlerlcsw" }
      ],
      specialties: [
        "Trauma-Focused CBT",
        "Somatic Interventions",
        "Dance/Movement Therapy",
        "Substance Use Recovery"
      ]
    },
    {
      id: 2,
      image: assistantImage,
      name: "Jenesis Matos",
      designation: "LMSW",
      title: "Clinician",
      description: `Jenesis brings over 16 years of experience in child welfare, along with a background in supporting children with special needs and families involved in family court proceedings. Compassionate and dedicated, Jenesis is committed to helping individuals and families navigate challenging circumstances with the care and advocacy they deserve.

      She integrates family systems, strengths-based, task-centered, trauma-informed, and person-centered approaches to provide compassionate, personalized care.`,
      specialties: [
        "Family Systems Therapy",
        "Child Welfare",
        "Trauma-Informed Care",
        "Family Court Support"
      ]
    }
  ];

  return (
    <React.Fragment>
      <NavBar />
      <section className="section bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="text-center">
              <div className="section-title mb-4 pb-2">
                <h4 className="title mb-4">Meet Our Team</h4>
                <p className="text-muted para-desc mx-auto mb-0">
                  Dedicated professionals committed to supporting your journey of healing and growth.
                </p>
              </div>
            </Col>
          </Row>

          {teamMembers.map((member, index) => (
            <Row key={member.id} className={`align-items-start ${index > 0 ? 'mt-5 pt-md-3' : ''}`}>
              <Col lg={12} md={12}>
                <Card className="border-0 rounded-md shadow">
                  <CardBody className="p-0">
                    <Row>
                      <Col lg={3} md={4}>
                        <div className="position-relative">
                          <img 
                            src={member.image} 
                            className="img-fluid rounded-top" 
                            alt={member.name}
                            style={{ 
                              width: '100%',
                              height: '300px',
                              objectFit: 'cover'
                            }}
                          />
                          {member.socialIds && member.socialIds.length > 0 && (
                            <div className="position-absolute bottom-0 start-0 p-4 w-100">
                              {member.socialIds.map((social, idx) => (
                                <Link 
                                  key={idx} 
                                  to={social.link} 
                                  target="_blank" 
                                  rel="noopener noreferrer" 
                                  className="btn btn-primary btn-pills btn-sm btn-icon me-1"
                                >
                                  <i><FeatherIcon icon={social.icon} className="fea icon-sm fea-social" /></i>
                                </Link>
                              ))}
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col lg={9} md={8}>
                        <div className="p-4">
                          <div className="title-heading mb-4">
                            <h4 className="mb-1">{member.name}</h4>
                            <p className="text-muted mb-0">{member.designation}</p>
                            <p className="text-primary">{member.title}</p>
                          </div>
                          <p className="text-muted">{member.description}</p>
                          <div className="mt-4">
                            <h6 className="mb-3">Areas of Expertise:</h6>
                            <Row>
                              {member.specialties.map((specialty, idx) => (
                                <Col key={idx} md={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <i className="uil uil-check-circle text-primary me-2"></i>
                                    <span>{specialty}</span>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ))}
        </Container>
      </section>
      <Footer5 />
    </React.Fragment>
  );
};

export default Team;
