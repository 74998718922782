import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import NavBar from './NavBar';
import Footer5 from '../Pages/PageFooterLayouts/Footer5';

const serviceData = [
  {
    icon: "uil uil-user-circle",
    title: "Individual Therapy",
    description: "One-on-one therapy sessions focused on personal growth and healing through evidence-based practices.",
    link: "/services/individual-therapy"
  },
  {
    icon: "uil uil-heart-rate",
    title: "Therapy with Movement and Dance",
    description: "Integrating movement and creative expression with traditional therapeutic approaches for holistic healing.",
    link: "/services/dance-therapy"
  },
  {
    icon: "uil uil-users-alt",
    title: "Group Therapy",
    description: "Supportive group sessions including therapy with movement and dance, music therapy, book clubs, and substance abuse recovery groups.",
    link: "/services/group-therapy"
  },
  {
    icon: "uil uil-building",
    title: "Business Consulting",
    description: "Professional guidance for businesses and employees, integrating therapeutic principles with organizational development.",
    link: "/services/business-consulting"
  },
  {
    icon: "uil uil-graduation-cap",
    title: "Clinical Supervision",
    description: "Supporting therapists in their professional development and clinical practice.",
    link: "/services/clinical-supervision"
  },
  {
    icon: "uil uil-presentation-play",
    title: "Workshops & Training",
    description: "Educational and experiential workshops for schools, workplaces, and healthcare facilities.",
    link: "/services/workshops"
  }
];

const Services = () => {
  const location = useLocation();
  const isStandalonePage = location.pathname === '/services';
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", scrollNavigation, true);

    // Handle hash navigation
    if (location.hash) {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        // Add delay to ensure DOM is ready
        setTimeout(() => {
          const navBar = document.getElementById("topnav");
          const offset = navBar ? navBar.offsetHeight : 0;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - offset - 20;
          
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }, 300);
      }
    }

    // Cleanup
    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, [location]);

  const scrollNavigation = () => {
    const navBar = document.getElementById("topnav");
    const top = window.pageYOffset || document.documentElement.scrollTop;
    
    if (navBar) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
      }
    }
  };

  return (
    <React.Fragment>
      <NavBar />
      <section className="section bg-light">
        <Container>
          <Row className="align-items-end mb-4 pb-2">
            <Col md={8}>
              <div className="section-title text-center text-md-start">
                <h6 className="text-primary">Services</h6>
                <h4 className="title mb-4">Our Services</h4>
                <p className="text-muted mb-0 para-desc">
                  Comprehensive therapy services tailored to your unique needs and goals.
                </p>
              </div>
            </Col>
          </Row>

          {/* Service Cards Row */}
          <Row>
            {serviceData.map((service, index) => (
              <Col lg={4} md={6} className="mt-4 pt-2" key={index}>
                <Card className="features feature-clean course-feature p-4 overflow-hidden shadow rounded h-100">
                  <div className="icons text-primary text-center">
                    <i className={`${service.icon} d-block rounded h3 mb-0`}></i>
                  </div>
                  <CardBody className="p-0 mt-4">
                    <h5 className="title text-dark">{service.title}</h5>
                    <p className="text-muted mt-2">{service.description}</p>
                    <Link 
                      to={service.link}
                      className="text-primary"
                    >
                      Read More <FeatherIcon icon="arrow-right" className="fea icon-sm" />
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          {/* Service Details Row */}
          <Row className="mt-5">
            <Col>
              {serviceData.map((service, index) => (
                <div 
                  key={`detail-${index}`}
                  id={`service-${index}`} 
                  className="service-detail mt-5"
                  style={{ display: selectedService === index ? 'block' : 'none' }}
                >
                  <div className="section-title">
                    <h4 className="title mb-4">{service.title}</h4>
                    {service.details}
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </section>
      {isStandalonePage && <Footer5 />}
    </React.Fragment>
  );
};

export default Services; 