import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <header id="topnav" className="defaultscroll sticky" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      backgroundColor: '#fff',
      boxShadow: '0 0 3px rgba(60,72,88,.15)'
    }}>
      <Container>
        <div>
          <Link className="logo" to="/">
            Homepage
          </Link>
        </div>
        
        <div className="menu-extras">
          <div className="menu-item">
            <button
              className={`navbar-toggle ${isOpen ? 'open' : ''}`}
              onClick={toggle}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>

        <div id="navigation" className={`${isOpen ? 'open' : ''}`}>
          <ul className="navigation-menu">
            <li><Link to="/team">Team</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/workshops">Workshops</Link></li>
            <li><Link to="/podcasts">Podcast</Link></li>
            <li>
              <a 
                href="https://www.instagram.com/estynadlerlcsw" 
                target="_blank" 
                rel="noopener noreferrer"
                className="nav-social-link"
              >
                <FeatherIcon icon="instagram" className="fea icon-sm" />
              </a>
            </li>
            <li><Link to="/contact" className="btn btn-primary">Book a Session</Link></li>
          </ul>
        </div>
      </Container>
    </header>
  );
};

export default NavBar; 