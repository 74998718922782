import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import NavBar from './NavBar';
import Footer5 from '../Pages/PageFooterLayouts/Footer5';
import podcastCover from '../../assets/images/podcast-cover.jpg';

const podcastData = {
  title: "Beyond The Session",
  description: "In this Derech Shalom Center podcast, our clinical directors, Dr. Shira Kessler PhD, LCSW, and Esty Nadler LCSW, delve into relevant emotional wellbeing and lifestyle topics that young adult women think about. Join them as they analyze topics to get you thinking & feeling. Beyond the session is an opportunity to listen in on what a session and connection feels like.",
  link: "https://open.spotify.com/show/1z1n7lJXqyHFj39kJfjlGH",
  featuredEpisode: {
    number: "29",
    title: "Closure (Season 1 Finale)",
    description: "In the final episode of Season 1, we discuss the importance of closure and what to do when closure cannot be attained.",
    duration: "33:27",
    date: "May 14, 2023",
    embedUrl: "https://creators.spotify.com/pod/show/beyondthesession/embed/episodes/29---Beyond-The-Session-Closure-Season-1-Finale-e23gk8c"
  }
};

const instagramPosts = [
  {
    url: "https://www.instagram.com/reel/CnfiJ7gKwL6/",
    embedUrl: "https://www.instagram.com/reel/CnfiJ7gKwL6/embed"
  },
  {
    url: "https://www.instagram.com/reel/CnPggPzqDpZ/",
    embedUrl: "https://www.instagram.com/reel/CnPggPzqDpZ/embed"
  },
  {
    url: "https://www.instagram.com/reel/Cnsf4yrL84D/",
    embedUrl: "https://www.instagram.com/reel/Cnsf4yrL84D/embed"
  }
];

const Podcasts = () => {
  // Add Instagram embed script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//www.instagram.com/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <React.Fragment>
      <NavBar />
      <section className="section">
        <Container>
          {/* Instagram Reels Section */}
          <Row className="justify-content-center mb-5">
            <Col lg={8} md={10}>
              <div className="section-title text-center mb-4">
                <h4 className="title mb-4">Featured Clips</h4>
              </div>
            </Col>
          </Row>
          
          <Row className="justify-content-center mb-5">
            {instagramPosts.map((post, index) => (
              <Col lg={4} md={6} className="mb-4" key={index}>
                <div 
                  className="instagram-media-wrapper"
                  style={{
                    maxWidth: '100%',
                    minWidth: '326px',
                    margin: '0 auto'
                  }}
                >
                  <iframe
                    src={post.embedUrl}
                    className="instagram-media"
                    width="100%"
                    height="580"
                    frameBorder="0"
                    scrolling="no"
                    style={{
                      background: '#FFF',
                      border: '1px solid #eee',
                      borderRadius: '3px',
                      boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
                    }}
                  ></iframe>
                </div>
              </Col>
            ))}
          </Row>

          {/* Existing Latest Episode Section */}
          <Row className="justify-content-center">
            <Col lg={8} md={10}>
              <div className="section-title text-center mb-5">
                <h4 className="title mb-4">Latest Episode</h4>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={10}>
              <Card className="shadow rounded border-0 overflow-hidden">
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={4}>
                      <img 
                        src={podcastCover}
                        alt="Beyond The Session Podcast"
                        className="img-fluid rounded"
                      />
                    </Col>
                    <Col md={8}>
                      <div className="ms-md-4 mt-4 mt-md-0">
                        <h5>Latest Episode</h5>
                        <h6 className="text-primary">#{podcastData.featuredEpisode.number} - {podcastData.featuredEpisode.title}</h6>
                        <p className="text-muted mb-2">{podcastData.featuredEpisode.description}</p>
                        <div className="d-flex align-items-center mt-3">
                          <span className="text-muted me-3">
                            <i className="uil uil-calendar-alt me-1"></i>
                            {podcastData.featuredEpisode.date}
                          </span>
                          <span className="text-muted">
                            <i className="uil uil-clock me-1"></i>
                            {podcastData.featuredEpisode.duration}
                          </span>
                        </div>
                        
                        {/* Spotify Episode Player */}
                        <div className="mt-4">
                          <iframe 
                            src={podcastData.featuredEpisode.embedUrl}
                            width="100%" 
                            height="102" 
                            frameBorder="0" 
                            scrolling="no"
                            allow="encrypted-media"
                            title="Spotify Episode Player"
                          ></iframe>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <div className="text-center mt-4">
                <a 
                  href={podcastData.link}
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="btn btn-primary"
                >
                  Listen to All Episodes on Spotify
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer5 />
    </React.Fragment>
  );
};

export default Podcasts; 