import React from 'react'
import { Navigate } from "react-router-dom";
import Podcasts from "../pages/Therapy/Podcasts";

//Main Index
import IndexMain from "../pages/Home/indexMain";
import TherapyIndex from "../pages/Therapy/index";

// Therapy Pages
import Team from "../pages/Therapy/Team";
import Services from "../pages/Therapy/Services";
import Workshops from "../pages/Therapy/Workshops";
import Contact from "../pages/Therapy/Contact";
import ServiceDetail from "../pages/Therapy/ServiceDetail";

//Auth Pages
import PageLogin from "../pages/Pages/AuthPages/PageLogin";

const routes = [
  { path: "/", component: <IndexMain />, isWithoutLayout: false },
  { path: "/therapy", component: <TherapyIndex />, isWithoutLayout: false },
  { path: "/team", component: <Team />, isWithoutLayout: false },
  { path: "/services", component: <Services />, isWithoutLayout: false },
  { path: "/services/:service", component: <ServiceDetail />, isWithoutLayout: false },
  { path: "/workshops", component: <Workshops />, isWithoutLayout: false },
  { path: "/contact", component: <Contact />, isWithoutLayout: false },
  { path: "/page-login", component: <PageLogin />, isWithoutLayout: false },
  { path: "/podcasts", component: <Podcasts />, isWithoutLayout: false },
  { path: "*", component: <Navigate to="/" replace />, isWithoutLayout: true }
];

export default routes;
