// React Basic and Bootstrap
import React, { useEffect } from 'react';

// Import Generic Components
import Section from '../Therapy/Section';
import Services from '../Therapy/Services';
import About from '../Therapy/About';
import WorkProcess from '../Therapy/WorkProcess';
import WhatWeTreat from '../Therapy/WhatWeTreat';
import Workshops from '../Therapy/Workshops';
import Footer5 from '../Pages/PageFooterLayouts/Footer5';
import Popup from '../../components/Layout/popup';
import NavBar from '../Therapy/NavBar';

const IndexMain = () => {
    const scrollNavigation = () => {
        var doc = document.documentElement;
        const navBar = document.getElementById("topnav");
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (navBar != null) {
            if (top > 80) {
                navBar.classList.add("nav-sticky");
            } else {
                navBar.classList.remove("nav-sticky");
            }
        }
    };
    
    useEffect(() => {
        document.body.classList = "";
        window.addEventListener("scroll", scrollNavigation, true);
        return () => {
            window.removeEventListener("scroll", scrollNavigation, true);
        };
    }, []);

    return (
        <React.Fragment>
            <NavBar />
            <div style={{ paddingTop: "10px" }}>
                <Section />
            </div>
            <Footer5 />
            <Popup />
        </React.Fragment>
    );
};

export default IndexMain;
