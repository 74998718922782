import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Input, Label, Alert } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import NavBar from './NavBar';
import Footer5 from '../Pages/PageFooterLayouts/Footer5';

const Contact = () => {
    const location = useLocation();
    const isStandalonePage = location.pathname === '/contact';
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const name = e.target.elements['entry.2005620554'].value;
        const email = e.target.elements['entry.1045781291'].value;
        const phone = e.target.elements['entry.phone'].value;
        const message = e.target.elements['entry.839337160'].value;

        try {
            const response = await fetch('/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, phone, message }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Something went wrong');
            }

            setSubmitted(true);
            setError('');
            e.target.reset();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (err) {
            console.error('Error details:', err);
            setError(err.message || 'There was an error sending your message. Please try again later.');
        }
    };

    return (
        <React.Fragment>
            <NavBar />
            <section className="section bg-light position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"></path>
                    </svg>
                </div>
                <Container className="position-relative">
                    {submitted && (
                        <Alert color="success" toggle={() => setSubmitted(false)} className="animated fadeIn">
                            <i className="uil uil-check-circle me-2"></i>
                            Thank you for your message. We'll get back to you soon!
                        </Alert>
                    )}
                    {error && (
                        <Alert color="danger" toggle={() => setError('')} className="animated fadeIn">
                            <i className="uil uil-exclamation-circle me-2"></i>
                            {error}
                        </Alert>
                    )}
                    
                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-3">Get in Touch</h4>
                                <p className="text-muted para-desc mx-auto">
                                    Schedule a free 10-minute consultation or reach out with any questions about therapy services and workshops.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center">
                        <Col lg={6} md={6}>
                            <Card className="border-0 rounded shadow p-4">
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">Name <span className="text-danger">*</span></Label>
                                                <div className="form-icon position-relative">
                                                    <i className="uil uil-user position-absolute mt-2 ms-3"></i>
                                                    <Input 
                                                        type="text" 
                                                        name="entry.2005620554"
                                                        className="form-control ps-5" 
                                                        placeholder="Your Name" 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label">Email <span className="text-danger">*</span></Label>
                                                <div className="form-icon position-relative">
                                                    <i className="uil uil-envelope position-absolute mt-2 ms-3"></i>
                                                    <Input 
                                                        type="email" 
                                                        name="entry.1045781291"
                                                        className="form-control ps-5" 
                                                        placeholder="Your Email" 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label">Phone</Label>
                                                <div className="form-icon position-relative">
                                                    <i className="uil uil-phone position-absolute mt-2 ms-3"></i>
                                                    <Input 
                                                        type="tel" 
                                                        name="entry.phone"
                                                        className="form-control ps-5" 
                                                        placeholder="Your Phone (optional)" 
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">Message <span className="text-danger">*</span></Label>
                                                <div className="form-icon position-relative">
                                                    <i className="uil uil-comment position-absolute mt-2 ms-3"></i>
                                                    <Input 
                                                        type="textarea" 
                                                        name="entry.839337160"
                                                        rows="4" 
                                                        className="form-control ps-5" 
                                                        placeholder="Your Message" 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <button type="submit" className="btn btn-primary w-100">
                                                Send Message <i className="uil uil-message ms-1"></i>
                                            </button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>

                        <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="ms-lg-4">
                                <div className="contact-detail d-flex flex-column bg-white rounded shadow p-4">
                                    <div className="title">
                                        <h4 className="mb-3">Contact Information</h4>
                                        <p className="text-muted mb-4">
                                            Feel free to reach out through any of these channels. I look forward to connecting with you.
                                        </p>
                                    </div>
                                    
                                    <div className="info-card mb-3 p-3 bg-light rounded">
                                        <div className="d-flex align-items-center">
                                            <div className="icon">
                                                <i className="uil uil-envelope-alt h5 text-primary mb-0"></i>
                                            </div>
                                            <div className="flex-1 ms-3">
                                                <h6 className="mb-0">Email</h6>
                                                <a href="mailto:estherkahanlcsw@gmail.com" className="text-muted">estherkahanlcsw@gmail.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="info-card mb-3 p-3 bg-light rounded">
                                        <div className="d-flex align-items-center">
                                            <div className="icon">
                                                <i className="uil uil-phone h5 text-primary mb-0"></i>
                                            </div>
                                            <div className="flex-1 ms-3">
                                                <h6 className="mb-0">Phone</h6>
                                                <a href="tel:8452930676" className="text-muted">(845) 293-0676</a>
                                            </div>
                                        </div>
                                    </div>

                                
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {isStandalonePage && <Footer5 />}
        </React.Fragment>
    );
};

export default Contact; 