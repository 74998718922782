import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import NavBar from './NavBar';
import Footer5 from '../Pages/PageFooterLayouts/Footer5';
import '../../assets/css/service-detail.css';

// Import images
import businessImg from '../../assets/images/therapy/business.jpg';
import groupTherapyImg from '../../assets/images/therapy/group-therapy.jpg';
import supervisionImg from '../../assets/images/therapy/supervision.jpg';
import therapyDanceImg from '../../assets/images/therapy/therapy-dance-movement.jpg';
import workshopImg from '../../assets/images/therapy/workshop.jpg';
import individualTherapyImg from '../../assets/images/therapy/individual-therapy.webp';

const serviceData = {
  'individual-therapy': {
    title: "Individual Therapy",
    description: "One-on-one therapy sessions focused on personal growth and healing through evidence-based practices.",
    icon: "uil uil-user-circle",
    image: individualTherapyImg,
    details: (
      <>
        <p>
          Individual therapy offers a personalized journey tailored to address each client's unique challenges and goals. Sessions are conducted once a week for 50 minutes, creating a dedicated space for growth and healing.
        </p>
        <p>
          Therapy helps clients explore patterns in their thoughts, emotions, and behaviors while supporting healing, managing stress, and building resilience. The goal is to empower clients with the tools and insights needed to create lasting change and live more fulfilling lives.
        </p>
      </>
    )
  },
  'dance-therapy': {
    title: "Therapy with Movement and Dance",
    description: "Integrating movement and creative expression with traditional therapeutic approaches for holistic healing.",
    icon: "uil uil-dance",
    image: therapyDanceImg,
    details: (
      <>
        <p>
          Therapy with movement and dance integrates movement with therapeutic principles to promote emotional, cognitive, and physical integration. This approach uses dance and movement to help clients express themselves, release emotions, and improve body awareness. It's particularly effective for those who find it challenging to articulate their feelings verbally.
        </p>
        <p>
          By engaging in creative movement, clients can explore their emotions in a non-verbal way, fostering a deeper connection between mind and body. This form of therapy supports emotional regulation, stress reduction, and enhances overall well-being.
        </p>
        <Link to="/contact" className="btn btn-primary">
          Schedule a Session
        </Link>
      </>
    )
  },
  'group-therapy': {
    title: "Group Therapy",
    description: "Supportive group sessions including therapy with movement and dance, music therapy, book clubs, and substance abuse recovery groups.",
    icon: "uil uil-users-alt",
    image: groupTherapyImg,
    details: (
      <>
        <h3>Therapy with Movement and Dance Group</h3>
        <p>
          The Therapy with Movement and Dance Group offers a safe and expressive environment where participants can explore their emotions and experiences through movement. The sessions incorporate creative dance and movement techniques that encourage self-expression, foster connection, and promote emotional healing. This group is ideal for individuals seeking to release tension, enhance body awareness, and connect with others in a supportive, non-judgmental setting.
        </p>

        <h3>Music Group</h3>
        <p>
          In the Music Group, participants select a song to listen to and explore its key themes and how they relate to their own lives. This collaborative approach encourages meaningful discussions and insights, fostering a sense of community and connection among members. By sharing personal reflections and experiences, participants can deepen their understanding of themselves while discovering how music serves as a powerful tool for emotional expression and healing.
        </p>

        <h3>Therapist Book Club</h3>
        <p>
          Join us for a unique monthly book club tailored specifically for therapists. Each session, we'll explore a selected book and delve into how its themes, characters, and insights intersect with our experiences and challenges as mental health professionals. Together, we'll reflect on its relevance to therapeutic practice and personal growth, fostering community and shared learning.
        </p>

        <h3>Client Book Club</h3>
        <p>
          Discover the power of stories in our monthly book club designed for personal growth and emotional well-being. Together, we'll explore a selected book, reflect on its themes, and connect them to our own journeys. This is a supportive space to share insights, foster understanding, and grow through the shared love of reading.
        </p>

        <h3>Substance Abuse Recovery Group for Women</h3>
        <p>
          The Substance Abuse Recovery Group for Women is a supportive space designed to address the unique challenges women face in recovery. This group focuses on building resilience, developing coping strategies, and empowering participants to reclaim their lives. Together, we create a community of understanding and strength, where women can share their experiences and support each other on the journey to healing and sobriety.
        </p>
        <Link to="/contact" className="btn btn-primary">
          Click here to be added to waitlist or call for more info
        </Link>
      </>
    )
  },
  'business-consulting': {
    title: "Business Consulting",
    description: "Professional guidance for businesses, organizations, and employees, integrating therapeutic principles with organizational development.",
    icon: "uil uil-building",
    image: businessImg,
    details: (
      <>
        <p>
          Our Business Consulting services are designed to help organizations and employees integrate therapeutic principles with strategic business development. We focus on enhancing team dynamics, improving communication, and fostering a healthy workplace culture. By applying therapeutic insights to business challenges, we aim to create environments where employees thrive and organizations achieve sustainable growth.
        </p>
        <Link to="/contact" className="btn btn-primary">
          Get Started Now
        </Link>
      </>
    )
  },
  'clinical-supervision': {
    title: "Clinical Supervision",
    description: "Supporting therapists in their professional development and clinical practice.",
    icon: "uil uil-graduation-cap",
    image: supervisionImg,
    details: (
      <>
        <p>
          In my role as a clinical supervisor, I provide support and guidance to therapists as they navigate the complexities of their clinical practice. Supervision sessions focus on enhancing clinical skills, addressing ethical dilemmas, and fostering professional development while ensuring that the highest standards of care are maintained. My approach is collaborative and reflective, encouraging supervisees to explore their therapeutic styles, engage in self-care practices, and develop a deeper understanding of the dynamics within the therapeutic relationship. By creating a safe and open environment, I aim to empower therapists to grow in their practice and effectively support their clients.
        </p>
      </>
    )
  },
  'workshops': {
    title: "Workshops & Training",
    description: "Educational and experiential workshops for schools, workplaces, and healthcare facilities.",
    icon: "uil uil-presentation-play",
    image: workshopImg,
    details: (
      <>
        <p>
          Our Workshops & Training programs are designed to cater to the specific needs of various organizations. We offer a range of educational and experiential workshops that focus on personal development, team building, emotional intelligence, and more. These sessions are interactive and tailored to foster growth, enhance communication, and build resilience within your team.
        </p>
        <p>
          Whether you're looking to empower your employees, support your students, or enhance your healthcare staff's skills, our workshops provide the tools and insights necessary to drive positive change and achieve your organizational goals.
        </p>
        <Link to="/contact" className="btn btn-primary">
          Get Started Now
        </Link>
      </>
    )
  }
  // Add other services here with their details
};

const ServiceDetail = () => {
  const { service } = useParams();
  const currentService = serviceData[service] || serviceData['individual-therapy'];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <NavBar />
      <section 
        className="bg-half-170 d-table w-100" 
        style={{ 
          backgroundImage: `url(${currentService.image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative'
        }}
      >
        <div className="bg-overlay bg-white" style={{ opacity: '0.8' }}></div>
        <Container className="position-relative" style={{ zIndex: 1 }}>
          <Row className="justify-content-center">
            <Col lg={8} className="text-center">
              <div className="page-next-level">
                <i className={`${currentService.icon} h1 text-primary`}></i>
                <h1 className="title mt-3 mb-4">{currentService.title}</h1>
                <p className="para-desc mx-auto text-muted">{currentService.description}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="service-content fade-in">
                <div className="service-details p-4 bg-white rounded shadow-sm">
                  {currentService.details}
                  <div className="text-center mt-4">
                    <Link to="/services" className="btn btn-secondary">
                      Back to Services
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer5 />
    </React.Fragment>
  );
};

export default ServiceDetail; 