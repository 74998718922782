import React from "react";
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import BackToTop from "../../../components/Layout/backToTop";
import '../../../assets/css/custom-colors.css';

const Footer5 = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-12">
              <div className="footer-py-30 text-center">
                <Row className="py-3">
                  <Col md={4}>
                    <div className="card border-0 text-center features feature-clean bg-transparent">
                      <div className="icons text-center mx-auto">
                        <i className="uil uil-phone d-block rounded h3 mb-0"></i>
                      </div>
                      <div className="content mt-3">
                        <h5 className="footer-head mb-2">Phone</h5>
                        <p className="text-muted mb-2">Schedule a free consultation or get in touch</p>
                        <Link to="tel:8452930676" className="text-white">(845) 293-0676</Link>
                      </div>
                    </div>
                  </Col>

                  <Col md={4} className="mt-3 mt-sm-0">
                    <div className="card border-0 text-center features feature-clean bg-transparent">
                      <div className="icons text-center mx-auto">
                        <i className="uil uil-envelope d-block rounded h3 mb-0"></i>
                      </div>
                      <div className="content mt-3">
                        <h5 className="footer-head mb-2">Email</h5>
                        <p className="text-muted mb-2">For inquiries about therapy services and workshops</p>
                        <Link to="mailto:estherkahanlcsw@gmail.com" className="text-white">estherkahanlcsw@gmail.com</Link>
                      </div>
                    </div>
                  </Col>

                  <Col md={4} className="mt-3 mt-sm-0">
                    <div className="card border-0 text-center features feature-clean bg-transparent">
                      <div className="icons text-center mx-auto">
                        <i className="uil uil-map-marker d-block rounded h3 mb-0"></i>
                      </div>
                      <div className="content mt-3">
                        <h5 className="footer-head mb-2">Services</h5>
                        <p className="text-muted mb-2">Individual Therapy • Group Therapy<br />Therapy with Movement and Dance • Business Consulting</p>
                        <Link to="/services" className="text-white">View All Services</Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="footer-py-20 footer-bar bg-footer">
          <Container className="text-center">
            <Row className="align-items-center justify-content-between">
              <Col lg={3} md={2} sm={3}>
                <div className="text-sm-start">
                  <Link to="/" className="text-foot">
                    Esther Kahan-Nadler
                  </Link>
                </div>
              </Col>

              <Col lg={6} md={6} sm={6} className="mt-2 mt-sm-0">
                <ul className="list-unstyled footer-list terms-service mb-0">
                  <li className="list-inline-item mb-0"><Link to="/about" className="text-foot me-2">About</Link></li>
                  <li className="list-inline-item mb-0"><Link to="/services" className="text-foot me-2">Services</Link></li>
                  <li className="list-inline-item mb-0"><Link to="/workshops" className="text-foot me-2">Workshops</Link></li>
                  <li className="list-inline-item mb-0"><Link to="/contact" className="text-foot">Contact</Link></li>
                </ul>
              </Col>

              <Col lg={3} md={4} sm={3} className="mt-2 mt-sm-0">
                <div className="text-sm-end">
                  <p className="mb-0 text-foot">© {(new Date().getFullYear())} Esther Kahan-Nadler, LCSW, CASAC</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
      <BackToTop />
    </React.Fragment>
  );
};

export default Footer5;
