import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "./Apps.scss";
import "./assets/css/custom-styles.scss";
import routes from "./routes/allRoutes";
import './assets/css/global.css';

const App = () => {
  return (
    <React.Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {routes.map((route, idx) => (
            <Route
              path={route.path}
              element={route.component}
              key={idx}
            />
          ))}
        </Routes>
      </Suspense>
    </React.Fragment>
  );
};

export default App;
